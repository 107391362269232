<i18n>
ru:
  cancelled: Отменен
  deleted: Удален
  deletedAutomatically: Удален автоматически
  deletedByGuest: Удален гостем вручную
  double: Дубликат
  errorSavingToBonusSystem: Ошибка сохранения в БС
  finished: Готов
  notEnoughPointsInBonusSystem: Недостаточно баллов
  notFoundInDB: Не найден в БД
  paid: Оплачен
  paymentFailed: Ошибка оплаты онлайн
  paymentProcessing: Обработка оплаты онлайн
  paymentReadyForProcessing: Готов к оплате онлайн
  postponed: Перенесен
  rClose: Выполнен
  rCooking: У повара
  rDelivered: Доставлен
  rDelivering: Доставляется
  rEdited: Отредактирован
  rExecuted: Исполнен
  rNew: Принят
  rNotConfirmed: Поступил
  rReady: Приготовлен
  rReceipt: Чек пробит
  rViewed: Просмотрен
  readyToSend: Готов к отправке
  saved: Сохранен
  savedLocally: Сохранен в БД
  sendFailed: Ошибка обработки
  sending: Отправляется
  sent: Отправлен
  setToSend: Вручную установлен к отправке
  test: Тестовый
  undefined: Статус неизвестен
ua:
  cancelled: Скасовано
  deleted: Видалено
  deletedAutomatically: Видалений автоматично
  deletedByGuest: Віддалений гостем вручну
  double: Дублікат
  errorSavingToBonusSystem: Помилка збереження в БС
  finished: Готовий
  notEnoughPointsInBonusSystem: Недостатньо балів
  notFoundInDB: Не знайдено в БД
  paid: Оплачено
  paymentFailed: Помилка оплати онлайн
  paymentProcessing: Обробка оплати онлайн
  paymentReadyForProcessing: Готовий до оплати онлайн
  postponed: Відкладено
  rClose: Виконано
  rCooking: У кухаря
  rDelivered: Доставлено
  rDelivering: Доставляється
  rEdited: Відредаговано
  rExecuted: Виконано
  rNew: Прийнято
  rNotConfirmed: Надійшов
  rReady: Приготований
  rReceipt: Чек пробитий
  rViewed: Переглянуто
  readyToSend: Готовий до відправки
  saved: Збережено
  savedLocally: Збережено в БД
  sendFailed: Помилка обробки
  sending: Відправляється
  sent: Відправлений
  setToSend: Вручну встановлено до відправки
  test: Тестовий
  undefined: Статус невідомий
us:
  cancelled: Cancelled
  deleted: Deleted
  deletedAutomatically: Deleted automatically
  deletedByGuest: Deleted by guest
  double: Duplicate
  errorSavingToBonusSystem: Error saving in bonus system
  finished: Finished
  notEnoughPointsInBonusSystem: Not enough points
  notFoundInDB: Not found in DB
  paid: Paid
  paymentFailed: Online payment error
  paymentProcessing: Processing online payment
  paymentReadyForProcessing: Ready for processing online payment
  postponed: Postponed
  rClose: Done
  rCooking: Cooking
  rDelivered: Delivered
  rDelivering: Delivering
  rEdited: Edited
  rExecuted: Executed
  rNew: New
  rNotConfirmed: Not confirmed
  rReady: Ready
  rReceipt: Receipt printed
  rViewed: Viewed
  readyToSend: Ready to send
  saved: Saved
  savedLocally: Saved in DB
  sendFailed: Failed to send
  sending: Sending
  sent: Sent
  setToSend: Manually set to send
  test: Test
  undefined: Unknown
</i18n>

<template>
  <div class="v-d-flex v-align-items-center v-order-status">
    <icon-old-general-dot
      v-if="showIcon"
      class="v-status-icon"
      :class="{
        'v-success-color': showColors && checkIfStatusSuccessful(status),
        'v-error-color': showColors && checkIfStatusUnsuccessful(status),
        'v-body-text-color-light':
          showColors &&
          !checkIfStatusSuccessful(status) &&
          !checkIfStatusUnsuccessful(status),
        'v-mr-xs': iconLeft,
        'v-status-icon--end v-ml-xs': !iconLeft
      }"
    />
    <span
      v-if="stringIsNullOrWhitespace(status.StatusTitle)"
      :class="{
        'v-success-color': showColors && checkIfStatusSuccessful(status),
        'v-error-color': showColors && checkIfStatusUnsuccessful(status),
        'v-body-text-color-light':
          showColors &&
          !checkIfStatusSuccessful(status) &&
          !checkIfStatusUnsuccessful(status)
      }"
      :data-test-id="`order-complete-status-${status.Status}`"
      v-html="localizedText"
    />
    <span
      v-else
      class="v-order-custom-status"
      :class="{
        'v-success-color': showColors && checkIfStatusSuccessful(status),
        'v-error-color': showColors && checkIfStatusUnsuccessful(status)
      }"
      :data-test-id="`order-complete-status-${status.Status}`"
      v-html="sanitize(status.StatusTitle)"
    />
  </div>
</template>

<script setup lang="ts">
import type { OrderInfoStatus } from '~types/clientStore'

import { useCommon } from '@arora/common'

import { OrderStatus } from '~api/consts'

const {
  iconLeft = true,
  showColors = true,
  showIcon = false,
  status
} = defineProps<{
  status: OrderInfoStatus
  showIcon?: boolean
  showColors?: boolean
  iconLeft?: boolean
}>()

const { sanitize, translate } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()

const { checkIfStatusSuccessful, checkIfStatusUnsuccessful } = useOrderInfo()

const localizedText = computed<string>(() => {
  switch (status.Status) {
    case OrderStatus.SavedLocally:
      return translate('orderStatus.savedLocally')
    case OrderStatus.Saved:
      return translate('orderStatus.saved')
    case OrderStatus.PaymentReadyForProcessing:
      return translate('orderStatus.paymentReadyForProcessing')
    case OrderStatus.PaymentProcessing:
      return translate('orderStatus.paymentProcessing')
    case OrderStatus.Paid:
      return translate('orderStatus.paid')
    case OrderStatus.Postponed:
      return translate('orderStatus.postponed')
    case OrderStatus.ReadyToSend:
      return translate('orderStatus.readyToSend')
    case OrderStatus.SetToSend:
      return translate('orderStatus.setToSend')
    case OrderStatus.Sending:
      return translate('orderStatus.sending')
    case OrderStatus.Sended:
      return translate('orderStatus.sent')
    case OrderStatus.PaymentFailed:
      return translate('orderStatus.paymentFailed')
    case OrderStatus.SendFailed:
      return translate('orderStatus.sendFailed')
    case OrderStatus.Cancelled:
      return translate('orderStatus.cancelled')
    case OrderStatus.R_NotConfirmed:
      return translate('orderStatus.rNotConfirmed')
    case OrderStatus.R_New:
      return translate('orderStatus.rNew')
    case OrderStatus.R_Viewed:
      return translate('orderStatus.rViewed')
    case OrderStatus.R_Edited:
      return translate('orderStatus.rEdited')
    case OrderStatus.R_Receipt:
      return translate('orderStatus.rReceipt')
    case OrderStatus.R_Cooking:
      return translate('orderStatus.rCooking')
    case OrderStatus.R_Delivering:
      return translate('orderStatus.rDelivering')
    case OrderStatus.R_Ready:
      return translate('orderStatus.rReady')
    case OrderStatus.R_Delivered:
      return translate('orderStatus.rDelivered')
    case OrderStatus.R_Close:
      return translate('orderStatus.rClose')
    case OrderStatus.R_Executed:
      return translate('orderStatus.rExecuted')
    case OrderStatus.Finished:
      return translate('orderStatus.finished')
    case OrderStatus.DeletedByGuest:
      return translate('orderStatus.deletedByGuest')
    case OrderStatus.DeletedAutomatically:
      return translate('orderStatus.deletedAutomatically')
    case OrderStatus.Double:
      return translate('orderStatus.double')
    case OrderStatus.Test:
      return translate('orderStatus.test')
    case OrderStatus.Deleted:
      return translate('orderStatus.deleted')
    case OrderStatus.NotFoundInDB:
      return translate('orderStatus.notFoundInDB')
    case OrderStatus.ErrorSavingToBonusSystem:
      return translate('orderStatus.errorSavingToBonusSystem')
    case OrderStatus.NotEnoughPointsInBonusSystem:
      return translate('orderStatus.notEnoughPointsInBonusSystem')
    default:
      return translate('orderStatus.undefined')
  }
})
</script>

<style lang="scss">
.v-order-status {
  line-height: inherit;
}

.v-status-icon {
  width: 9px;
  height: 9px;
  margin-bottom: 1px;

  &--end {
    order: 1;
  }
}
</style>
